import React from "react"
import Page from "../components/page"
import Contact from "../components/sections/contact"
import Footer from "../components/sections/footer"
import GoToTopButton from "../components/goToTopButton"
import styles from './404.module.css';
import { graphql } from "gatsby"
import buttonStyles from "../styles/buttons.module.css"
import { SITE_URL } from "../config/url"

export default function Home({ location, data }) {
    return (
      <Page title={"Lori Books – Boudoir Photo Albums"} location={location}>
          <div>

              <div className={styles.main}>
                <h2>The page you requested could not be found</h2>
                  <a href={SITE_URL} className={styles.button}>
                      <div className={buttonStyles.button}>HOME</div>
                  </a>
              </div>

              <Contact/>

              <Footer images={data}/>

              <GoToTopButton/>
          </div>
      </Page>
    )
}

export const query = graphql`
query {
     fbIcon: file(relativePath: { eq: "fb-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    inIcon: file(relativePath: { eq: "in-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    piIcon: file(relativePath: { eq: "pi-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    
    twIcon: file(relativePath: { eq: "tw-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    } 
    
   
    
   }
`